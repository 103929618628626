<template>
  <div id="app" class="">
    <div class="layout">
      <table class="table" border="0">
        <tr>
          <td colspan="1">
            Şirket:
            <el-select v-model="selection">
              <el-option value="mimsanbant">mimsanbant</el-option>
            </el-select>

          </td>
          <td colspan="1">
            Dil :
            <el-select v-model="language">
              <el-option value="tr">tr</el-option>
              <el-option value="en">en</el-option>
            </el-select>
          </td>
          <td colspan="1">

          </td>
        </tr>
        <tr>
          <td colspan="3">
            <br/>
            <hr/>
            <br/>
          </td>
        </tr>
        <tr>
          <td>Adı Soyadı
            <el-input v-model="form.name"></el-input>
          </td>
          <td>Görevi
            <el-input v-model="form.profession"></el-input>
          </td>
          <td>
            Bilgi
            <el-input v-model="form.title"></el-input>
          </td>
        </tr>
        <tr>
          <td>
            Mail
            <el-input v-model="form.email"></el-input>
          </td>
          <td>
            GSM
            <el-input v-model="form.gsm"></el-input>
          </td>
        </tr>
      </table>
      <br/>
      <div class="show" style="padding: 10px;">

        <div class="preview">

          <table style="border:0px;width: 600px;" v-if="selection === 'mimsanbant'">
            <tr>
              <td style="text-align: left !important;" colspan="3">
                <h4 style="font-size:16px;font-weight: bold;padding:0;margin:0px;">{{ form.name }}</h4>
                <h6 style="font-size:13px;font-weight: normal;padding:0;margin:0px;">{{ form.profession }}</h6>
                <h6 style="font-size:13px;font-weight: normal;padding:0;margin:0px;">{{ form.title }}</h6>
                <div style="height: 10px;"></div>
              </td>
            </tr>
            <tr>
              <td style="text-align: left !important;" colspan="3"><h4
                  style="font-size:14px;font-weight: normal;padding:0;margin:0px;"><strong>E-MAIL :</strong>
                {{ form.email }}</h4></td>
            </tr>
            <tr>
              <td style="text-align: left !important;" colspan="3"><h4
                  style="font-size:14px;font-weight: normal;padding:0;margin:0px;"><strong>GSM :</strong> {{ form.gsm }}
              </h4></td>
            </tr>
            <tr>
              <td style="text-align: left !important;" colspan="3"></td>
            </tr>
            <tr>
              <td style="text-align: left !important;" valign="top">
                <img src="/logo-1.jpg" style="height: 90px;" v-if="language === 'tr'"/>
                <img src="/logo-1-en.jpg" style="height: 90px;" v-if="language === 'en'"/>
              </td>
              <td style="text-align: left !important;font-size: 12px" colspan="2" >
                <span v-html="defaults.mimsan.address"></span><br/>
                TEL : {{ defaults.mimsan.tel1 }}<br/>
                FAX : {{ defaults.mimsan.tel2 }}<br/>
                WEB : <a :href="defaults.mimsan.web">{{ defaults.mimsan.web }}</a><br/>
                E-MAIL : {{ defaults.mimsan.email }}
              </td>
            </tr>
            <tr>
              <td style="text-align: left !important;" colspan="3">
                <div style="height: 10px;"></div>
                <img src="/logo-2.jpeg" style="height: 32px;margin-right: 2px"/>
                <img src="/logo-3.jpeg" style="height: 32px;margin-right: 2px"/>
                <img src="/logo-4.jpeg" style="height: 32px;margin-right: 2px"/>
                <img src="/logo-5.jpeg" style="height: 32px;margin-right: 2px"/>
                <img src="/logo-6.jpeg" style="height: 32px;margin-right: 2px"/>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="selection === 'mimsanbant' && language==='tr'" style="font-size: 12px;text-align: left">
          Bu mesaj ve mesaja eklenmiş olan dosyalar kişiye özeldir. Eğer bu mesaj bir hata sonucu size ulaşmış ise
          mesaji hemen siliniz ve gönderen kişiye gerçekleşen yanlışlığı bildiriniz. Mesajın gönderildiği kişi siz
          değilseniz, mesaji ya da eklerini kopyalayamaz, başkasına gönderemez ve açıklayamazsınız.
          <strong>MİMSAN BANT</strong> mesaj içeriğinin bozulması, mesajin geç ulaşması veya mesajın virüs taşıması gibi
          sorunlardan sorumlu tutulamaz. Bu mesajdaki ifadelerin tümü mesaji gönderen kişiye aittir. <strong>MİMSAN
          BANT</strong> mesajin gönderiminden, içeriğinden veya iceriğine güvenilerek yapılacak işlemlerden dolayı
          sorumlu tutulamaz.
        </div>
        <div v-if="selection === 'mimsanbant' && language==='en'" style="font-size: 12px;text-align: left">
          This e-mail is strictly confidential and intended solely for the addressee. It may contain information, which is covered by legal, professional, or other privilege. If you are not the intended addressee you must not use, disclose or copy this transmission. This e-mail is not intended to impose nor shall it be construed as imposing any legally binding obligation upon Mimsan Bant and/or any of its subsidiaries or associated companies. Neither Mimsan Bant nor any of its subsidiaries or associated companies gives any representation or warranty as to the accuracy or completeness of the content of this e-mail. Mimsan Bant shall not be held liable to any person resulting from the use of any information contained in this e-mail and shall not be liable to any person who acts or omits to do anything in reliance upon it.
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      selection: 'mimsanbant',
      language: 'tr',
      form: {
        name: null,
        profession: null,
        title: null,
        email: null,
        gsm: null,
        tel: null,
        adres: null,
      },
      defaults: {
        mimsan: {
          address: 'Ömerli Mh. Uzunçayır Cd. No:15<br/>34555A ARNAVUTKÖY / İSTANBUL ',
          tel1: '+90 212 657 8946',
          tel2: '+90 212 651 0953',
          web: 'www.mimsanbant.com.tr',
          email: 'info@mimsanbant.com.tr'
        }
      }
    }
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.layout {
  padding: 0;
  margin: 0 auto;
  padding-top: 10px !important;
  max-width: 1250px;
}

table {
  width: 100%;
}

.preview {
  /*margin: 0 auto !important;*/
  max-width: 600px;
}
</style>
